<template>
  <div class="answer">
    <v-list
      class="faq-block"
      :subheader="false"
      v-for="(answer, index) in answers.items"
      :key=index
    >
      <v-list-item-group>
        <v-list-item>
          <v-list-item-avatar class="count">
            {{index + 1}}
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ $t(answer.title) }}</v-list-item-title>
            <v-list-item-subtitle v-html="$t(answer.text)"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  computed: {
    answers () {
      let faq = this.$store.state.faq.all.find(faq => faq.id === this.$route.params.id);
      if (faq) {
        return faq;
      }
      return {};
    }
  }
};
</script>
